export const TPIIApis = {
  getCustomerNumber:
    "https://theprogram.vodafone.co.nz/api/customer?search_type=sam_id&info_flag=1&search_value=",
  getTPIICartridgeNumber:
    "https://theprogram.vodafone.co.nz/api/customer?search_type=brm_id&search_value=",
  getTPIICustomerData: "https://theprogram.vodafone.co.nz/api/customer/",
  getTPIIToolData: "https://theprogram.vodafone.co.nz/api/tools/",
};

export const tooltipsConfig = {
  UpdateTime: {
    text:
      "This data is correct as of the date shown, changes may have occurred within the last 48Hrs, please check CRM / interactions if necessary as it is the only single source of updated information.",
    size: "four-line",
    linkText: "",
    linkUrl: "",
  },
  lineTestPort: {
    text:
      "Displays the port customer's connection is provisioned on by Chorus. If customer is experiencing connectivity issues check their modem is plugged into the correct port.",
    size: "four-line",
    linkText: "",
    linkUrl: "",
  },
  ModemAuth: {
    text:
      "Note, it takes up to 5 minutes for updated results to show after a modem restart.",
    size: "two-line",
    linkText: "",
    linkUrl: "",
  },
  LAN: {
    text:
      "If the TPII SV/CV LAN numbers show preceding zeroes, it remains a match and requires no action / changes.",
    size: "three-line",
    linkText: "",
    linkUrl: "",
  },
  LoginHistory: {
    text:
      "<p>Displays log of disconnections including modem restarts.  If less than 5 per day, then issue is with internal network.</p><p><strong>Please note:</strong> If modem has not restarted in the last 30 days, history data may not be displayed.</p>",
    size: "six-line",
    linkText: "Radius Logs - Frequent disconnections",
    linkUrl: "https://k-hub.vodafone.nz/radius-tools-view-disconnection-logs",
  },
  ONT: {
    text: "If service is down. Check if ONT is powered on.",
    size: "two-line",
    linkText: "Chorus Fibre ONT status",
    linkUrl:
      "https://k-hub.vodafone.nz/chorus-assurance-run-fibre-diagnostic-tests",
  },
  InternetUsage: {
    text: "Displays usage summary for the current month.",
    size: "two-line",
    linkText: "Check broadband usage",
    linkUrl: "https://k-hub.vodafone.nz/tp2-check-broadband-usage",
  },
  ONTOverTime: {
    text:
      "<p>The daily view of the ONT status (top part of the widget) represents an aggregated view of each hourly poll made during that day. If any one hour poll shows a colour other than green then the daily status will reflect this. </p><p>This is determined by the following priority order: </p><p>Red>Orange>Black>Yellow>Green>Grey</p><p>For example, if any one hour poll shows red, then that will be the status-colour for that day. If there is no red, but one or more hourly segment shows orange then the status-colour for the day will show as orange – and so on. </p>",
    size: "nine-line",
    linkText: "More information on ONT's",
    linkUrl: "https://k-hub.vodafone.nz/search?q=ont",
  },
  Outages: {
    text:
      "<p>Please view event details to determine if this outage is still affecting the customer or if the outage is now closed or soon to be closed.</p>",
    size: "three-line",
    linkText: "",
    linkUrl: "",
  },
  PinVerify: {
    text:
      "<p>Ensure your customer is authenticated using the 4 digit PIN or security questions.</p>",
    size: "three-line",
    linkText: "KB article",
    linkUrl:
      "https://k-hub.vodafone.nz/siebel-on-account-fixed-line-consumer-security-id-questions",
  },
};

export const faqOptions = [
  {
    value: "OneAssist | Summary",
    label: "OneAssist | Summary",
    link: "https://k-hub.vodafone.nz/oneassist-summary",
  },
  {
    value: "OneAssist | How to login",
    label: "OneAssist | How to login",
    link: "https://k-hub.vodafone.nz/oneassist-how-to-login",
  },
  {
    value: "OneAssist | Open a customer account",
    label: "OneAssist | Open a customer account",
    link: "https://k-hub.vodafone.nz/oneassist-open-customer-account",
  },
  {
    value: "OneAssist | Run a Fibre diagnostic test",
    label: "OneAssist | Run a Fibre diagnostic test",
    link: "https://k-hub.vodafone.nz/oneassist-run-fibre-diagnostic-tests",
  },
  {
    value: "No Internet BPP",
    label: "No Internet BPP",
    link: "https://k-hub.vodafone.nz/no-fixed-line-internet",
  },
  {
    value: "Frequent Disconnections BPP",
    label: "Frequent Disconnections BPP",
    link: "https://k-hub.vodafone.nz/frequent-fixed-line-disconnections",
  },
  {
    value: "Slow Speeds BPP",
    label: "Slow Speeds BPP",
    link:
      "https://k-hub.vodafone.nz/slow-speed-fixed-line-slow-speed-troubleshooting",
  },
  {
    value: "Knowledge Hub | Tool Box",
    label: "Knowledge Hub | Tool Box",
    link: "https://k-hub.vodafone.nz/tool-box",
  },
  {
    value: "All network outages",
    label: "All network outages",
    link:
      "https://l.workplace.com/l.php?u=https%3A%2F%2Fwww.vodafone.co.nz%2Fhelp%2Fnetwork-status%2F&h=AT1YRNOUbcA90anH0PTV83RJVRQDn4mQfJT6ToRj_jC0mtmtoMoLwgK5BzZ-LkaK9gC6A-grQLQk-8xNb7Ia5hVRaE3TtVp2NaGKLNTdn4-NYziYQFNpGjSXH7sSnuwqYs9EJuyF74Zm2Kzu4_QuEi1_mFe8eg&__tn__=-UK-R&c[0]=AT3jzQGfP0ZGlnVqMNWNj07mPOjCGpatH098RYck7Jed_dPNitE5QIRQqhtea80UwTrUzVPMUq2qppPj_VgX7JvJg3UrXhxacgafVyvQjrsNQSTo6QmTng4_tm4i49ejIV9G8IlHTKWfvXfVTt8uO_1Nkh0jwWIsXylhGsE",
  },
  {
    value: "Chorus outages",
    label: "Chorus outages",
    link:
      "https://l.workplace.com/l.php?u=https%3A%2F%2Fwww.chorus.co.nz%2Foutages&h=AT1IER41rq2NrV0BcEEGlptck20FwrKsqzzuOhOqA-dWg3ghkfKycKvcI5rJ1QKAc9wpnjijHHlPYHF4ljudx3yhcuR1DsME-bMh8oIhOIAfjVaHfGtFApEQFlBJfvl4n79hRBlf7wqzL07HX9atP759d9aCGw&__tn__=-UK-R&c[0]=AT3jzQGfP0ZGlnVqMNWNj07mPOjCGpatH098RYck7Jed_dPNitE5QIRQqhtea80UwTrUzVPMUq2qppPj_VgX7JvJg3UrXhxacgafVyvQjrsNQSTo6QmTng4_tm4i49ejIV9G8IlHTKWfvXfVTt8uO_1Nkh0jwWIsXylhGsE",
  },
  {
    value: "All NZ Outage page",
    label: "All NZ Outage page",
    link:
      "https://l.workplace.com/l.php?u=https%3A%2F%2Fahunga.sharepoint.com%2Fsites%2FNZOutagesNotifications&h=AT2R1vNEYqjt4ydEKQ-PtCOwZUIQnC1G_LbaByVjahh7D5fA1GejAuyUoc2KBLjASBkrE_wU2FWt4dCZTZ_55XdesCrH1gR2unRYO_2EZeH52t-FW4UjJBwALPL3kUSYVDcevW-GmVIFsHvbJdc5RlBa4SRixQ&__tn__=-UK-R&c[0]=AT3jzQGfP0ZGlnVqMNWNj07mPOjCGpatH098RYck7Jed_dPNitE5QIRQqhtea80UwTrUzVPMUq2qppPj_VgX7JvJg3UrXhxacgafVyvQjrsNQSTo6QmTng4_tm4i49ejIV9G8IlHTKWfvXfVTt8uO_1Nkh0jwWIsXylhGsE",
  },
];

export const siebelPinOptions = [
  {
    value: "Authenticate your customer",
    label: "Authenticate your customer",
    link: "https://k-hub.vodafone.nz/security-id-a-customer",
  },
  {
    value: "Security ID FAQs",
    label: "Security ID FAQs",
    link: "https://k-hub.vodafone.nz/security-id-faqs",
  },
  {
    value: "Security ID | Guidelines",
    label: "Security ID | Guidelines",
    link: "https://k-hub.vodafone.nz/aws-security-id-guidelines-about",
  },
  {
    value:
      "Security ID | Siebel On Account/Fixed Line Consumer Security Questions",
    label:
      "Security ID | Siebel On Account/Fixed Line Consumer Security Questions",
    link:
      " https://k-hub.vodafone.nz/siebel-on-account-fixed-line-consumer-security-id-questions",
  },
  {
    value: "Security ID | TheProgramII Consumer Security Questions",
    label: "Security ID | TheProgramII Consumer Security Questions",
    link:
      "https://k-hub.vodafone.nz/theprogramii-consumer-security-id-questions",
  },
  {
    value: "Security ID | How to check your customer's physical ID",
    label: "Security ID | How to check your customer's physical ID",
    link:
      "https://k-hub.vodafone.nz/security-id-how-to-check-your-customers-physical-id",
  },
  {
    value: "OneView Authentication SMS | Summary",
    label: "OneView Authentication SMS | Summary",
    link: "https://k-hub.vodafone.nz/authentication-sms-summary",
  },
];

type WifiConfig = {
  [key: string]: {
    headline: string;
    message_header: string;
    message_body: string;
    shown_dropdown: string;
    dropdown_title: string;
    dropdown: { value: string; label: string; link: string }[];
  };
};

export const wifiConfig: WifiConfig = {
  212: {
    headline:
      "OneAssist has not detected any issues - Is the customer still experiencing issues?",
    message_header: "RUN DECO CLOUD",
    message_body:
      "No significant issues have been detected - See results below\nOneAssist is currently unable to test the network further(Eg:Home WIFI, Devices, etc...)",
    shown_dropdown: "yes",
    dropdown_title: "Deco Support",
    dropdown: [
      {
        value: "Deco cloud BPP",
        label: "Deco cloud BPP",
        link: "https://k-hub.vodafone.nz/oneassist-summary",
      },
      {
        value: "WIFI speed test",
        label: "WIFI speed test",
        link: "https://k-hub.vodafone.nz/oneassist-how-to-login",
      },
    ],
  },
  213: {
    headline:
      "OneAssist has not detected any issues - Is the customer still experiencing issues?",
    message_header: "Run Wifi Help (RouteThis)",
    message_body:
      "No significant issues have been detected - See results below\nOneAssist is currently unable to test the network further(Eg:Home WIFI, Devices, etc...)",
    shown_dropdown: "yes",
    dropdown_title: "More information",
    dropdown: [
      {
        value: "TEST LINK",
        label: "TEST LINK",
        link: "https://google.com",
      },
      {
        value: "WIFI Help",
        label: "WIFI Help",
        link: "https://one.nz/help/broadband/wifi-help/",
      },
    ],
  },
};
